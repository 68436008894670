import { PDF } from "./constants/payoffPlanner";

const DEV = "DEV";
const PROD = "PROD";
const RNDEV = "RNDEV";
const LOCAL = "LOCAL";

const ENV = DEV;

export const getPayoffPlannerExportDataURLv3 = ({
  organizationId,
  format = PDF,
}) =>
  ({
    [DEV]: `https://us-central1-devtlloanapp-3d093.cloudfunctions.net/orgPayoffPlanner/organization/${organizationId}/export/${format}/data`,
    [PROD]: `https://us-central1-tlloanapp-d0571.cloudfunctions.net/orgPayoffPlanner/organization/${organizationId}/export/${format}/data`,
    [LOCAL]: `http://localhost:5001/devtlloanapp-3d093/us-central1/orgPayoffPlanner/organization/${organizationId}/export/${format}/data`,
  }[ENV]);

export const cloudFunctionUrlV3 = () =>
  ({
    [DEV]:
      "https://asia-south1-devtlloanapp-3d093.cloudfunctions.net/orgLoansExport",
    [RNDEV]:
      "https://asia-south1-react-native-firebase-7aef1.cloudfunctions.net/orgLoansExport",
    [PROD]:
      "https://asia-south1-tlloanapp-d0571.cloudfunctions.net/orgLoansExport",
    [LOCAL]:
      "http://localhost:5001/devtlloanapp-3d093/asia-south1/orgLoansExport",
  }[ENV]);

export const collectionListUrlV3 = () =>
  ({
    [DEV]:
      "https://us-central1-devtlloanapp-3d093.cloudfunctions.net/orgApi/collectionList/data",
    [PROD]:
      "https://us-central1-tlloanapp-d0571.cloudfunctions.net/orgApi/collectionList/data",
    [LOCAL]:
      "http://localhost:5001/devtlloanapp-3d093/us-central1/orgApi/collectionList/data",
  }[ENV]);

export const fetchSharedLoanDataUrlV3 = () =>
  ({
    [DEV]:
      "https://us-central1-devtlloanapp-3d093.cloudfunctions.net/orgApi/fetchSharedLoan",
    [PROD]:
      "https://us-central1-tlloanapp-d0571.cloudfunctions.net/orgApi/fetchSharedLoan",
    [LOCAL]:
      "http://localhost:5001/devtlloanapp-3d093/us-central1/orgApi/fetchSharedLoan",
  }[ENV]);

export const deepLinkURLv3 = ({ invitationId, utmCampaign }) => {
  return {
    [PROD]: `https://freebird.page.link?link=http://sharing.getfreebird.app/v3/i/${invitationId}&apn=com.tilicho.lendpal&isi=1244075045&ibi=com.tilicho.TLLoanApp&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,
    [DEV]: `https://devfreebird.page.link?link=http://devsharing.getfreebird.app/v3/i/${invitationId}&apn=com.tilicho.freebirdDev&isi=1536121860&ibi=com.tilicho.freebird-Dev&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,

    [LOCAL]: `https://devfreebird.page.link/?link=http://devsharing.getfreebird.app/v3/i/${invitationId}&apn=com.tilicho.freebirdDev&isi=1536121860&ibi=com.tilicho.freebird-Dev&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,
  }[ENV || DEV];
};

// Prod config
const firebaseConfigProd = {
  apiKey: "AIzaSyCEW8a8HSP5HVCcBqn_BaBzb_cM30WT81g",
  authDomain: "tlloanapp-d0571.firebaseapp.com",
  databaseURL: "https://tlloanapp-d0571.firebaseio.com",
  projectId: "tlloanapp-d0571",
  storageBucket: "tlloanapp-d0571.appspot.com",
  messagingSenderId: "875949104053",
  appId: "1:875949104053:web:d26e0bf56fa56e53e8d7fc",
};

// Dev config
const firebaseConfigDev = {
  apiKey: "AIzaSyDAG4gc06UAZq_gmvWl2dVXecIEJKq3LzQ",
  authDomain: "devtlloanapp-3d093.firebaseapp.com",
  databaseURL: "https://devtlloanapp-3d093.firebaseio.com",
  projectId: "devtlloanapp-3d093",
  storageBucket: "devtlloanapp-3d093.appspot.com",
  messagingSenderId: "1062454949888",
  appId: "1:1062454949888:web:f6c3ea837a82681f2a80e5",
};

export const firebaseConfig = () => {
  return {
    [PROD]: firebaseConfigProd,
    [DEV]: firebaseConfigDev,
    [LOCAL]: firebaseConfigDev,
  }[ENV || DEV];
};
