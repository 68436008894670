import "./index.css";

import * as LF from "../../api/firebase/loanFields";

import {
  ADD_INTEREST_FOR_PARTIAL_DAYS,
  CALCULATION_STRATEGY_KEY,
  CALCULATION_STRATEGY_OPTIONS_KEY,
  DEFAULT_CALCULATION_OPTIONS,
} from "../../utils/constants/calculationOptionsConstants";
import {
  APP_BACKGROUND_COLOUR_DARK,
  BACKGROUND_DARK,
  BUTTON_LENT_LIGHT_GRADIENT_START,
  BUTTON_LIGHT,
  DARK_GREY_BLUE,
  LENT,
  LIGHT_GREY_SUBTEXT_COLOR,
  MAIN_TEXT_DARK,
  OWES,
  PROGRESS_BAR_LENT_GRADIENT_END,
  PROGRESS_BAR_OWES_GRADIENT_END,
  SEGMENTED_ACTIVE_TEXT_LIGHT,
  SEGMENTED_INACTIVE_BACKGROUND_DARK,
  SEGMENTED_INACTIVE_BACKGROUND_LIGHT,
  SEGMENTED_INACTIVE_TEXT_LIGHT,
  SHADOW_BLACK50,
  SUB_TEXT_LIGHT,
  TEXT_FIELD_ERROR_BORDER,
  TRANSPARENT,
  WHITE,
} from "../../constants/colors";
import {
  COMPOUND_INTEREST,
  SIMPLE_INTEREST,
  compoundInterestValues,
} from "../../utils/LoanUtils";
import {
  DynamicStyleSheet,
  DynamicValue,
  Image,
  SectionList,
  Text,
  TouchableOpacity,
  View,
  useDarkMode,
  useDynamicStyleSheet,
} from "../react-native/imports";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  TRANSACTION_TYPE_LENT,
  TRANSACTION_TYPE_OWES,
} from "../../constants/LoanConstants";
import {
  capitalize,
  cloneDeep,
  compact,
  drop,
  filter,
  find,
  first,
  floor,
  forEach,
  get,
  isEmpty,
  isObject,
  last,
  map,
  round,
  sumBy,
  values,
} from "lodash";
import { deepLinkURLv3, fetchSharedLoanDataUrlV3 } from "../../env";
import { isIOS, isMobile } from "react-device-detect";

import { CircularProgress } from "@material-ui/core";
import { INTEREST_CALCULATION_STRATEGY } from "../../constants/LoanConstants";
import IndividualLoanCard from "./IndividualLoanCard";
import LoanPaymentComponent from "./LoanPaymentComponent";
import Modal from "react-modal";
import { ProgressBar } from "./ProgressBar";
import { REGULAR } from "../../constants/fonts";
import { SegmentedControl } from "segmented-control-react";
import axios from "axios";
import { format } from "../../utils/CurrencyFormatter";
import { formatDate } from "../../utils/DateFormatter";
import { getUserByLoanInfo } from "../../api/firebase/methods";
import moment from "moment";
import qs from "query-string";
import { withFirebase } from "../Firebase/context";

Modal.setAppElement("#root");

export const generateDate = (loan, settleUp, onlySettleUpDate = false) => {
  let summary = loan.settleUpId && settleUp ? settleUp.summary : {};
  //let payment = values(loan.paidInfo || {}) || [];
  const paidOnDate =
    loan.paidInfo && values(loan.paidInfo || {}).length > 0
      ? last(values(loan.paidInfo || {})).paidOn
      : 0;
  let settledOnDate = loan.settleUpId ? summary.dueDate : paidOnDate;

  return onlySettleUpDate
    ? loan.loanStatus !== "Closed"
      ? loan.dueDate || null
      : settledOnDate
    : loan.loanStatus !== "Closed"
    ? loan.createDate
    : !isEmpty(loan.badLoanInfo)
    ? loan.badLoanInfo.paidOn
    : settledOnDate;
};

const TopSection = memo(({ loan, buddy, globalCurrency }) => {
  const { loanSettleUpData } = loan;
  let item = {
    itemType: "transaction",
    transaction: loan,
    date: generateDate(loan, loanSettleUpData),
    name: get(loan, loan.isSharedLoan ? "info.owner.name" : "friend.name", ""),
  };

  return (
    <View style={{ marginVertical: 15 }}>
      <IndividualLoanCard
        loan={item}
        buddy={buddy}
        numberOfLines={1}
        globalCurrency={globalCurrency}
        disable={true}
        mode={"fromLoanDetails"}
      />
    </View>
  );
});

const dDetails = new DynamicStyleSheet({
  container: {
    backgroundColor: new DynamicValue("white", BACKGROUND_DARK),
    marginVertical: 15,
    shadowOpacity: 0.75,
    shadowRadius: 4,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
    shadowOffset: { width: 0, height: 1 },
    elevation: 4,
  },
  innerContainer: {
    marginVertical: 5,
    paddingLeft: 8,
    paddingRight: 8,
  },
  detailsElementContainer: {
    flexDirection: "row",
    marginHorizontal: 15,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    width: "100%",
  },
  detailsText: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    paddingTop: 14,
    paddingBottom: 5,
  },
  detailsLeftText: {
    textAlignVertical: "top",
    textAlign: "left",
    letterSpacing: 0.82,
  },
  detailsRightText: {
    textAlign: "right",
    fontFamily: REGULAR,
    fontWeight: 550,
    letterSpacing: 0.76,
  },
  subValueText: {
    textAlign: "right",
    fontFamily: REGULAR,
    letterSpacing: 0.76,
    fontSize: 14,
    textDecorationLine: "line-through",
  },
  interestBreakupButtonText: {
    color: BUTTON_LIGHT,
  },
  notes: {
    fontFamily: REGULAR,
    fontSize: 12,
    color: new DynamicValue("#19313c", MAIN_TEXT_DARK),
    // TODO: Change the darkmode color here
  },
  labelDefaultColour: {
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
  },
  underLineStyle: {
    height: 1,
    backgroundColor: SUB_TEXT_LIGHT,
  },
  interestStyles: {
    fontFamily: REGULAR,
    fontSize: 12,
    color: new DynamicValue(LIGHT_GREY_SUBTEXT_COLOR, MAIN_TEXT_DARK), //TODO: Change the dark mode color
    paddingTop: 5,
    paddingVertical: 15,
  },
});

const interestRateTitle = (loan) => {
  const interestType = loan.interestType || "Monthly";
  const interestMode = loan.interestMode || SIMPLE_INTEREST;
  const noInterst = loan.interestRate === 0;
  if (noInterst) {
    return "Interest Rate";
  }
  switch (interestType) {
    case "Monthly":
      return interestMode === "simpleInterest"
        ? "Simple Interest Rate"
        : "Compound Interest Rate";
    case "Quarterly":
    case "Half_yearly":
    case "Annually":
      return "Compound Interest Rate";
    case "Yearly":
      return "Simple Interest Rate";
    default:
      return "";
  }
};

const interestRateAmount = (loan) => {
  const interestMode = loan.interestMode || SIMPLE_INTEREST;
  const interestType = loan.interestType || "Monthly";
  let subText = "";
  const noInterst = loan.interestRate === 0;
  if (noInterst) {
    return (subText = "NA");
  } else if (interestMode === COMPOUND_INTEREST) {
    subText = "per year";
  } else {
    switch (interestType) {
      case "Monthly":
        subText = "/ month";
        break;
      case "Quarterly":
      case "Half_yearly":
      case "Annually":
      case "Yearly":
        subText = "/ year";
        break;
      default:
        subText = "";
    }
  }

  switch (interestMode) {
    case SIMPLE_INTEREST:
      return `${round(
        loan.interestRate
          ? (loan.interestRate || 0) / compoundInterestValues(loan.interestType)
          : 0,
        2
      )}% ${subText}`;
    case COMPOUND_INTEREST:
      return `${
        loan.interestRate ? round(loan.interestRate || 0, 2) : 0
      }% ${subText}`;
    default:
      return `0% ${subText}`;
  }
};

const DetailsElement = ({
  label,
  value,
  subValue,
  componentAtRight,
  noUnderlines,
  loan,
  showDetails,
  dontShow,
  dontShowSubValue = false,
  Component = View,
  componentProps = {},
}) => {
  const styles = useDynamicStyleSheet(dDetails);
  const transactionTypeOwe = loan.isLoanTransactionTypeOwe;

  let viewStyle = styles.detailsElementContainer;
  if (noUnderlines === true && label !== "Notes") {
    viewStyle = { ...viewStyle, ...{ marginTop: -12 } };
  }

  let leftTextStyle = {
    ...styles.detailsText,
    ...styles.detailsLeftText,
  };

  if (noUnderlines === true && label !== "Notes") {
    leftTextStyle = {
      ...leftTextStyle,
      ...{ paddingTop: 5, fontSize: 12 },
    };
  } else if (label === "Notes") {
    leftTextStyle = {
      ...leftTextStyle,
      ...{ paddingBottom: 0 },
    };
  }

  let rightTextStyle = { ...styles.detailsText, ...styles.detailsRightText };
  if (showDetails && subValue && !dontShowSubValue) {
    rightTextStyle = {
      ...rightTextStyle,
      ...{
        color: !transactionTypeOwe ? LENT : OWES,
      },
    };
  } else {
    rightTextStyle = {
      ...rightTextStyle,
      ...styles.labelDefaultColour,
    };
  }

  return (
    <>
      {dontShow ? null : (
        <Component {...componentProps}>
          <View style={viewStyle}>
            <View>
              <Text style={leftTextStyle}>{label}</Text>
            </View>
            {componentAtRight ? (
              value
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {subValue && !dontShowSubValue ? (
                  <Text
                    style={{
                      ...styles.detailsText,
                      ...styles.detailsRightText,
                      ...styles.subValueText,
                      color: !transactionTypeOwe ? LENT : OWES,
                      marginRight: 5,
                    }}
                  >
                    {subValue}
                  </Text>
                ) : null}
                <Text style={rightTextStyle}>{value}</Text>
              </View>
            )}
          </View>
          {noUnderlines ? null : <View style={styles.underLineStyle} />}
        </Component>
      )}
    </>
  );
};

const DetailsSection = ({
  loan,
  globalCurrency,
  onClickLoanImage,
  showMoreDetails,
  shouldExcludeFromSummary,
  setExcludeFromSummary,
  isBadLoan,
}) => {
  const styles = useDynamicStyleSheet(dDetails);
  const badLoan = isBadLoan;

  const interestCalculationOptions =
    loan.interestCalculationOptions || DEFAULT_CALCULATION_OPTIONS;

  const {
    preparedData: {
      isClosedLoan,
      loanTotalOutstandingAmount,
      loanTotalOutstandingPrincipal,
      loanInterestAmount,
      loanInterestAmountWithDetails,
      loanInterestAmountWithoutDetails,
      loanInterestBetweenMonths,
      loanLastIntervalEndDate,
      loanLatestReferenceDate,
      loanMonthlyInterestPayables,
      loanDurationPeriod,
    } = {},
    interestCalculationStrategy = interestCalculationOptions[SIMPLE_INTEREST][
      CALCULATION_STRATEGY_KEY
    ],
    lastRemainingInterest = 0,
  } = loan;

  const hasSchedules =
    loan.recurringPaymentInfo &&
    loan.recurringPaymentInfo.schedule &&
    loan.recurringPaymentInfo.schedule.length > 0 &&
    find(
      loan.recurringPaymentInfo.schedule,
      (item) => item.status === "None"
    ) !== undefined;

  const isDailyFinance = loan.paymentType === FLAT_INTEREST;

  const hasTransactionSchedules =
    !isClosedLoan &&
    loan.recurringPaymentInfo &&
    loan.recurringPaymentInfo.schedule &&
    loan.recurringPaymentInfo.schedule.length > 0;

  const outstandingAmount = loanTotalOutstandingAmount;
  const outstandingPrincipal = loanTotalOutstandingPrincipal;

  const lastSchedule = hasSchedules
    ? last(loan.recurringPaymentInfo.schedule)
    : [];
  const noInterest = !loan.interestRate;

  const loanSettled = isClosedLoan && !isBadLoan;
  const totalInterestPaid =
    sumBy(
      values(loan.paidInfo || {}),
      (info) => info.interestBefore - info.interestAfter
    ) || 0;

  let isOverDued =
    lastSchedule && lastSchedule.paidOn * 1000 < moment().startOf("day");

  const emptyPaidInfo = isEmpty(loan.paidInfo);
  const isCompoundInterest = loan.interestMode === COMPOUND_INTEREST;

  let amountDueSubVal = loanSettled
    ? false
    : outstandingAmount ===
      (outstandingAmount === outstandingPrincipal
        ? loan.principalAmount
        : isCompoundInterest && emptyPaidInfo
        ? loan.principalBalance
        : hasTransactionSchedules && !isOverDued
        ? loan.principalBalance
        : outstandingPrincipal);
  const principalSubVal = loanSettled
    ? true
    : loan.principalBalance === loan.principalAmount;
  let intAmount =
    hasSchedules && !isOverDued
      ? outstandingAmount - loan.principalBalance
      : loanInterestAmount;

  let interestAmountWithDetails = loanInterestAmountWithDetails;
  let interestAmountWithoutDetails =
    hasTransactionSchedules && !isOverDued && !emptyPaidInfo
      ? 0
      : loanInterestAmountWithoutDetails;

  const interestSubVal =
    loanSettled ||
    interestAmountWithDetails === 0 ||
    interestAmountWithoutDetails === 0 ||
    interestAmountWithDetails === intAmount;
  const clearanceSubVal = loan.recurringPaymentInfo
    ? loan.recurringPaymentInfo.schedule &&
      loan.recurringPaymentInfo.schedule.length > 0 &&
      lastSchedule.status === "None" &&
      generateDate(loan, null, true)
      ? false
      : true
    : true;

  const checkFutureDate =
    loanInterestBetweenMonths.fromDate >= loanInterestBetweenMonths.toDate;

  let isPartialDays =
    interestCalculationOptions[SIMPLE_INTEREST][
      CALCULATION_STRATEGY_OPTIONS_KEY
    ][INTEREST_CALCULATION_STRATEGY.MONTH_END][ADD_INTEREST_FOR_PARTIAL_DAYS];

  let daysBasedStrategy =
    loan.interestMode === SIMPLE_INTEREST &&
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.DAY_END;

  let detailsData = [];

  if (isDailyFinance) {
    const { interestInfo = {} } = loan;
    const {
      details: { installments, tenure },
      schedules = {},
    } = interestInfo;

    const allSchedules = values({ ...schedules });
    const firstSchedule = first(allSchedules) || {};
    const lastSchedule = last(allSchedules) || {};

    detailsData = [
      {
        label:
          badLoan || loanSettled ? "Principal Amount" : "Principal Balance",
        value: format(
          loanSettled && !badLoan
            ? loan.principalAmount
            : loan.principalBalance,
          loan.currency || globalCurrency
        ),
      },
      {
        label: `${tenure} Payment`,
        value: format(firstSchedule?.amount, loan.currency || globalCurrency),
      },
      {
        label: "Total Installments",
        value: installments,
      },
      {
        label: "Pending Installments",
        value:
          filter(
            values(schedules) || [],
            (schedule) => schedule?.status === "pending"
          )?.length || 0,
      },
      {
        label: "Closed By",
        value: moment(lastSchedule?.paidOn).format("DD MMM YYYY"),
      },
    ];
  } else {
    detailsData = [
      {
        label: "Amount Due",
        value: format(outstandingAmount, loan.currency || globalCurrency),
        subValue: showMoreDetails
          ? outstandingAmount === outstandingPrincipal
            ? format(loan.principalAmount, loan.currency || globalCurrency)
            : format(
                isCompoundInterest && emptyPaidInfo
                  ? loan.principalBalance
                  : hasTransactionSchedules && !isOverDued
                  ? loan.principalBalance
                  : outstandingPrincipal,
                loan.currency || globalCurrency
              )
          : null,
        dontShow: badLoan || noInterest || loanSettled,
        dontShowSubValue: amountDueSubVal,
      },
      {
        label:
          badLoan || loanSettled ? "Principal Amount" : "Principal Balance",
        value: format(
          loanSettled && !badLoan
            ? loan.principalAmount
            : daysBasedStrategy || isPartialDays
            ? loan.principalBalance
            : loanLastIntervalEndDate &&
              loanLastIntervalEndDate <= moment().startOf("day")
            ? loan.principalBalance
            : outstandingAmount,
          loan.currency || globalCurrency
        ),
        subValue: showMoreDetails
          ? format(
              loanSettled ? 0 : loan.principalAmount,
              loan.currency || globalCurrency
            )
          : null,
        dontShow: false,
        dontShowSubValue: principalSubVal,
      },
      {
        label: (
          <View>
            <Text>{"Interest Outstanding"}</Text>
            {lastRemainingInterest ? (
              <Text style={styles.interestStyles}>
                {"\n"}
                {format(lastRemainingInterest, loan.currency || globalCurrency)}
                {" - "}
                {"Pending till "}
                {moment(
                  (loan.interestMode === SIMPLE_INTEREST
                    ? loanLatestReferenceDate
                    : loan.compoundInterestReferenceIntervalDate) * 1000
                )
                  .subtract(1, "day")
                  .format("DD MMM YYYY")}
                {"\n"}
              </Text>
            ) : null}
            <Text style={styles.interestStyles}>
              {format(
                intAmount - lastRemainingInterest,
                loan.currency || globalCurrency
              )}
              {" - "}
              {loan.interestMode === COMPOUND_INTEREST && !hasSchedules
                ? `(last remaining interest till ${moment(
                    loanLastIntervalEndDate
                  ).format("DD MMM YYYY")})`
                : `(${
                    loan.interestMode === SIMPLE_INTEREST
                      ? moment(loanLatestReferenceDate * 1000).format(
                          "DD MMM YYYY"
                        )
                      : moment(
                          loan.compoundInterestReferenceIntervalDate * 1000
                        ).format("DD MMM YYYY")
                  } - ${
                    hasSchedules && !isOverDued
                      ? lastSchedule.status === "None"
                        ? moment(lastSchedule.paidOn * 1000).format(
                            "DD MMM YYYY"
                          )
                        : moment().format("DD MMM YYYY")
                      : checkFutureDate
                      ? ""
                      : moment().format("DD MMM YYYY")
                  }) `}
            </Text>
            <Text style={styles.interestStyles}>{loanDurationPeriod}</Text>
          </View>
        ),
        value: format(
          loanSettled ||
            (!isPartialDays &&
              !daysBasedStrategy &&
              loanLastIntervalEndDate > moment().startOf("day"))
            ? 0
            : intAmount,
          loan.currency || globalCurrency
        ),

        subValue: showMoreDetails
          ? format(
              isClosedLoan ? 0 : interestAmountWithoutDetails,
              loan.currency || globalCurrency
            )
          : null,
        dontShow: badLoan ? true : !isClosedLoan ? noInterest : true,
        dontShowSubValue: interestSubVal,
      },
      {
        label: interestRateTitle(loan),
        value: `${interestRateAmount(loan)}`,
        dontShow: false,
      },
      {
        label:
          badLoan || loanSettled ? "Interest Incurred" : "This Month Interest",
        value: badLoan
          ? loan.badLoanInfo &&
            format(
              loan.badLoanInfo.interestBefore,
              loan.currency || globalCurrency
            )
          : loanSettled
          ? round(totalInterestPaid, 2)
          : `${
              loan.transactionType === TRANSACTION_TYPE_LENT
                ? loanMonthlyInterestPayables.lentMonthlyInterest
                : loanMonthlyInterestPayables.owedMonthlyInterest
            }`,
        dontShow:
          isCompoundInterest ||
          (badLoan || loanSettled ? (noInterest ? true : false) : noInterest),
      },
      {
        label: "Bad Loan Amount",
        value:
          loan.badLoanInfo &&
          format(loan.badLoanInfo.amount, loan.currency || globalCurrency),
        dontShow: !badLoan,
      },
      {
        label: "Repayment Total",
        value: format(
          sumBy(values(loan.paidInfo || {}) || [], (item) => item.amount),
          loan.currency || globalCurrency
        ),
        dontShow: values(loan.paidInfo).length === 0,
        //get(loan, "paidInfo.length", 0) === 0,
      },
      {
        label: "Grace Amount",
        value: format(loan.grace, loan.currency || globalCurrency),
        dontShow: !loan.grace,
      },
      {
        label: `Loan Clearance Date${!clearanceSubVal ? "\n(Estimated)" : ""}`,
        value: (
          <Text
            style={
              loan.dueDate && !clearanceSubVal && showMoreDetails
                ? (loanSettled
                    ? generateDate(loan)
                    : loan.recurringPaymentInfo &&
                      lastSchedule.status === "None"
                    ? lastSchedule.paidOn
                    : loan.dueDate) <
                  (loanSettled
                    ? loan.recurringPaymentInfo
                      ? lastSchedule.paidOn
                      : loan.dueDate
                    : loan.dueDate)
                  ? { color: PROGRESS_BAR_LENT_GRADIENT_END }
                  : { color: PROGRESS_BAR_OWES_GRADIENT_END }
                : {}
            }
          >
            {loanSettled
              ? formatDate({
                  millis: generateDate(loan),
                  defaultValue: "NA",
                })
              : formatDate({
                  millis:
                    loan.recurringPaymentInfo && lastSchedule.status === "None"
                      ? lastSchedule.paidOn
                      : loan.dueDate,
                  defaultValue: "NA",
                })}
          </Text>
        ),
        subValue: showMoreDetails
          ? loanSettled
            ? formatDate({
                millis: loan.recurringPaymentInfo
                  ? lastSchedule.paidOn
                  : loan.dueDate,
                defaultValue: "NA",
              })
            : formatDate({
                millis: loan.dueDate,
                defaultValue: "NA",
              })
          : null,
        dontShowSubValue: clearanceSubVal,
        dontShow: loanSettled
          ? false
          : formatDate({
              millis: loan.dueDate,
              defaultValue: "NA",
            }) === "NA" &&
            formatDate({
              millis: loan.recurringPaymentInfo
                ? lastSchedule.paidOn
                : loan.dueDate,
              defaultValue: "NA",
            }) === "NA",
      },
    ];
  }
  if (!isEmpty(loan?.customFields)) {
    forEach(values(loan.customFields), (custField) => {
      if (custField?.name && custField?.name.length > 0) {
        let label = custField.name;
        let value = custField.value;
        detailsData.push({
          label: label,
          value: value,
        });
      }
    });
  }

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        {map(detailsData, (item, index) => (
          <DetailsElement
            {...item}
            key={index}
            loan={loan}
            showDetails={showMoreDetails}
          />
        ))}
      </View>
    </View>
  );
};

const width = window.innerWidth;

const dStyles = new DynamicStyleSheet({
  textStyle: {
    marginHorizontal: 15,
    marginTop: 5,
    fontFamily: REGULAR,
    fontSize: 12,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    letterSpacing: 0.71,
  },
  numberTextStyle: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    letterSpacing: 0.82,
  },
  sectionHeader: {
    marginBottom: 10,
    marginTop: 10,
  },
  sectionHeaderText: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    letterSpacing: 0.82,
  },
  containerStyle: {
    flex: 1,
    backgroundColor: new DynamicValue(TRANSPARENT, APP_BACKGROUND_COLOUR_DARK),
    marginTop: 100,
    width: "100%",
  },
  modalStyle: {
    backgroundColor: "rgba(44,83,100,0.4)",
    flex: 1,
    margin: 0,
  },
  recordNewPaymentButton: {
    alignSelf: "center",
    justifyContent: "center",
    width: "90%",
    height: 60,
    borderRadius: 6,
  },
  recordNewPaymentButtonText: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: new DynamicValue(WHITE, WHITE),
    letterSpacing: 0.82,
  },
  buttonBaseView: {
    height: 90,
    backgroundColor: new DynamicValue("#FFFFFF", BACKGROUND_DARK),
    alignItems: "center",
    justifyContent: "center",
    shadowOpacity: 0.75,
    shadowRadius: 4,
    elevation: 4,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
    shadowOffset: { width: 0, height: 1 },
  },
  segmentViewStyle: {
    alignSelf: "center",
    maxWidth: width - 30,
    flex: 1,
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: new DynamicValue("#D8D8D8", TRANSPARENT),
    borderRadius: 7,
    overflow: "hidden",
    width: "100%",
    marginTop: 15,
  },
  segmentControllerStyle: {
    height: 32,
    maxWidth: "70%",
    flex: 1,
  },
  RFLContainer: {
    backgroundColor: new DynamicValue(WHITE, BACKGROUND_DARK),
    padding: 12,
    marginHorizontal: 15,
    marginBottom: 15,
    borderRadius: 5,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,.25)"),
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
  RFLTop: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  repaymentsText: {
    fontSize: 17,
    fontFamily: REGULAR,
    letterSpacing: 0.3,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
  },
  RFLButtonStyles: {
    paddingHorizontal: 13,
    paddingTop: 8,
    paddingBottom: 7,
    borderRadius: 5,
  },
  RFLBottom: {
    fontFamily: REGULAR,
    fontSize: 12,
    lineHeight: 17,
    letterSpacing: 0,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    marginTop: 8,
  },
  invalidPaymentPlan: {
    fontFamily: REGULAR,
    fontSize: 12,
    color: TEXT_FIELD_ERROR_BORDER,
    marginVertical: 5,
  },
  progressView: {
    marginHorizontal: 15,
    height: 5,
  },
  loanInfoContainer: {},
  loanDetailsTextContainer: {
    marginHorizontal: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  loanDetailsText: {
    letterSpacing: 1.0,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
    fontSize: 17,
    fontFamily: REGULAR,
  },
  appInstallContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    marginTop: 10,
  },
  seeAdvantagesButton: {
    marginTop: -50,
    padding: 4,
    border: 1,
    borderColor: BUTTON_LENT_LIGHT_GRADIENT_START,
  },
  seeAdvantagesButtonAndroid: {
    marginTop: -5,
    padding: 4,
    border: 1,
    borderColor: BUTTON_LENT_LIGHT_GRADIENT_START,
  },
  seeAdvantagesButton1: {
    padding: 4,
    border: 1,
    borderColor: BUTTON_LENT_LIGHT_GRADIENT_START,
  },
  seeAdvantagesLabel: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: BUTTON_LENT_LIGHT_GRADIENT_START,
  },
  appInstallAdvantages: {
    alignItems: "center",
  },
});

export const DEFAULT_PAYMENT = "default payment";
export const RECORD_NEW_PAYMENT = "record new payment";
export const PAY_REPAYMENT = "REPAID";
export const EDIT_AND_PAY_REPAYMENT = "PARTIAL";
export const SKIP_REPAYMENT = "SKIPPED";
export const FLAT_INTEREST = "flat_interest";

export const SharedLoanV3 = withFirebase(({ navigation, route }) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [showMoreDetails, setshowMoreDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(null);
  const [loan, setLoan] = useState({});
  const [buddy, setBuddy] = useState({});
  const [invitation, setInvitation] = useState({});
  const [utmCampaign, setUTMCampaign] = useState("");

  const {
    badLoanInfo,
    shouldExcludeFromSummary,
    currency,
    preparedData: {
      dispatchesArray,
      payments,
      isLoanTransactionTypeOwe,
      loanProgressPercent,
    } = {},
  } = loan;
  const isBadLoan = !isEmpty(badLoanInfo);

  useEffect(() => {
    setShowLoader(true);

    const url = window.location.pathname;
    const [invitationId, query] = url
      .substring(url.lastIndexOf("/") + 1)
      ?.split?.("?");

    if (!invitationId) {
      window.location.href = "https://www.getfreebird.app/";
    }

    //Adding utm campaign for tracking
    const queryParams = qs.parse(query);
    const { utmCampaign: utmC } = queryParams;

    utmC && setUTMCampaign(utmC);

    (async () => {
      try {
        const { data: { loan, invitation, buddy = {} } = {} } = (
          await axios.get(
            `${fetchSharedLoanDataUrlV3()}?invitationId=${invitationId}`
          )
        ).data;

        setLoan(loan);
        setBuddy(buddy);
        setInvitation(invitation);
      } catch (err) {
        console.log({ err });
        setError(err?.response?.data || err);
      } finally {
        setShowLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectedIndex(
      get(loan, "recurringPaymentInfo.schedule.length") > 0
        ? 1
        : payments?.length > 0
        ? 1
        : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan.recurringPaymentInfo]);

  const styles = useDynamicStyleSheet(dStyles);
  const isDarkMode = useDarkMode();

  const transactionType = isLoanTransactionTypeOwe
    ? TRANSACTION_TYPE_OWES
    : TRANSACTION_TYPE_LENT;

  const hasSetPlan = !!loan.recurringPaymentInfo;
  let scheduledPayments = hasSetPlan
    ? cloneDeep(loan.recurringPaymentInfo.schedule || [])
    : [];

  let filteredSchedulePayments =
    scheduledPayments.length > 0
      ? scheduledPayments.filter((item) => {
          return item.status === "None";
        })
      : [];

  const isShowSchedulePayments = false;

  const clearedPercentage =
    loan.loanStatus === LF.LOAN_STATUS.CLOSED && !isBadLoan
      ? 100
      : loanProgressPercent;

  const ClearedPercentageView = memo(() => {
    return (
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Text style={styles.textStyle}>
          Cleared{" "}
          <Text style={styles.numberTextStyle}>{clearedPercentage}</Text>%
        </Text>
        <Text style={styles.textStyle}>
          <Text style={styles.numberTextStyle}>{100 - clearedPercentage}</Text>%{" "}
          {isBadLoan ? "Bad Loan" : "Remaining"}{" "}
        </Text>
      </View>
    );
  });

  const renderTopSection = useCallback(() => {
    return (
      <View>
        <TopSection loan={loan} buddy={buddy} globalCurrency={currency} />
        <ProgressBar
          style={styles.progressView}
          width={
            loan.loanStatus === LF.LOAN_STATUS.CLOSED && !isBadLoan
              ? 100
              : Math.max(loanProgressPercent, 3)
          }
          mode={"fromLoanDetails"}
          isTintOwes={transactionType === TRANSACTION_TYPE_OWES}
          isBadLoan={isBadLoan}
          containerStyle={{ height: 8 }}
        />
        <ClearedPercentageView />
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, loan, buddy]);

  const renderMiddleSection = useCallback(() => {
    return (
      <View style={styles.loanInfoContainer}>
        <View
          style={styles.loanDetailsTextContainer}
          className="view-horizontal"
        >
          <Text style={styles.loanDetailsText}>
            {!showMoreDetails ? "Information" : "More Information"}
          </Text>
          {((loan.principalBalance !== loan.principalAmount && isBadLoan) ||
            !isBadLoan) && (
            <TouchableOpacity
              onPress={() => {
                setshowMoreDetails(!showMoreDetails);
              }}
              className="information-toggle-button"
            >
              <Image
                style={{ width: 42, height: 42 }}
                source={
                  showMoreDetails
                    ? transactionType === TRANSACTION_TYPE_LENT
                      ? require("../../assets/eyeFilledLentIcon.png")
                      : require("../../assets/eyeFilledOweIcon.png")
                    : isDarkMode
                    ? require("../../assets/eyeIcon-white.png")
                    : require("../../assets/eyeIcon.png")
                }
              />
            </TouchableOpacity>
          )}
        </View>
        <DetailsSection
          loan={loan}
          globalCurrency={currency}
          onClickLoanImage={() => {}}
          showMoreDetails={showMoreDetails}
          shouldExcludeFromSummary={shouldExcludeFromSummary}
          setExcludeFromSummary={() => {}}
          isBadLoan={isBadLoan}
        />
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, loan, showMoreDetails]);

  const renderFourthSection = useCallback(() => {
    const vals = isShowSchedulePayments
      ? [{ name: "Dispatches" }, { name: "Scheduled" }, { name: "History" }]
      : [{ name: "Dispatches" }, { name: "History" }];
    return (
      <>
        <View style={styles.segmentViewStyle}>
          <SegmentedControl
            style={styles.segmentControllerStyle}
            segments={vals}
            selected={selectedIndex}
            onChangeSegment={setSelectedIndex}
            variant="dark"
            backgroundColor={
              isDarkMode
                ? SEGMENTED_INACTIVE_BACKGROUND_DARK
                : SEGMENTED_INACTIVE_BACKGROUND_LIGHT
            }
            textColor={
              isDarkMode ? MAIN_TEXT_DARK : SEGMENTED_INACTIVE_TEXT_LIGHT
            }
            activeTextColor={
              isDarkMode ? MAIN_TEXT_DARK : SEGMENTED_ACTIVE_TEXT_LIGHT
            }
            tintColor={isDarkMode ? BACKGROUND_DARK : WHITE}
          />
        </View>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode, loan, selectedIndex]);

  const sections = useMemo(
    () => [
      {
        title: "#",
        data: [0],
        renderItem: renderTopSection,
      },
      {
        title: "$",
        data: [1],
        renderItem: renderMiddleSection,
      },
      {
        title: "*",
        data: [2],
        renderItem: renderFourthSection,
      },
      {
        title: "lastSection",
        data:
          selectedIndex === 0
            ? dispatchesArray
            : selectedIndex === 1 && isShowSchedulePayments
            ? filteredSchedulePayments
            : payments,
        renderItem: ({ item, index }) => {
          const transformedPaidInfo = payments;

          const paidAmountTillDate = sumBy(
            drop(transformedPaidInfo, index),
            "amount"
          );
          const totalPaidAmount = sumBy(transformedPaidInfo, "amount");

          const percentage =
            paidAmountTillDate /
            (totalPaidAmount + loan?.preparedData?.loanTotalOutstandingAmount ||
              0);

          return (
            <LoanPaymentComponent
              payment={item}
              currency={loan.currency || currency}
              loanType={transactionType}
              settleUpId={loan.settleUpId}
              editPayment={false}
              navigation={navigation}
              principalAmount={loan.principalAmount}
              errorBanner={"Some error"}
              isScheduled={isShowSchedulePayments && selectedIndex === 1}
              isFirstPayment={
                index === 0 && isShowSchedulePayments && selectedIndex === 1
              }
              changeModalType={"default"}
              percentage={floor(percentage * 100)}
              loan={loan}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currency, loan, buddy, showMoreDetails, selectedIndex]
  );

  // useEffect(() => {
  //   checkLoansharedInfo();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const checkLoansharedInfo = async () => {
  //   const byloanInfo =
  //     (await getUserByLoanInfo(loan.owner.id, loan.transactionId)) || {};
  //   if (!isEmpty(byloanInfo)) {
  //   }
  // };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    };
  }, [isModalOpen]);

  const WEBSITE_URL = "https://www.getfreebird.app/";

  if (showLoader) {
    return (
      <View id="loader-container">
        <CircularProgress color="primary" />
      </View>
    );
  }

  console.log({ error, message: error?.message });
  return (
    <div id="body">
      <div className="container">
        {!!error && (
          <View>
            <View id="error-banner">
              <img src={require("../../assets/info-48.png")} alt="" />
              <b>{capitalize(error.message || error)}</b>
            </View>
            <iframe
              src={WEBSITE_URL}
              title="Free Bird"
              className="website-iframe-full"
            />
          </View>
        )}
        {!isEmpty(loan) && (
          <View
            className={
              isMobile ? "master-container1" : "master-container1-shrinked"
            }
          >
            {isMobile && (
              <View className="header-logo-container">
                <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
                  <Image
                    source={require("../../assets/headerLogo.png")}
                    style={{ height: 40, marginLeft: 8 }}
                  />
                </a>
              </View>
            )}
            {!isModalOpen && (
              <ShareFooter
                openModal={() => setIsModalOpen(true)}
                invitation={invitation}
                utmCampaign={utmCampaign}
              />
            )}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Example Modal"
              className="modal"
              disableAutoFocus={true}
            >
              <div className="modal-content">
                <AppInstallAdvantages
                  invitation={invitation}
                  utmCampaign={utmCampaign}
                />
              </div>
            </Modal>
            <View className="master-container">
              <SectionList
                sections={sections}
                keyExtractor={(item, index) =>
                  isObject(item)
                    ? item.dispatchId
                      ? item.dispatchId
                      : isShowSchedulePayments && selectedIndex === 1
                      ? item.paidOn + index + item.amount
                      : item.paidOn + index
                    : item + index
                }
                renderSectionHeader={({ title, data }) => {
                  if (title === "lastSection") {
                    //show List Empty Text
                    if (
                      (selectedIndex === 1 &&
                        isShowSchedulePayments &&
                        filteredSchedulePayments.length === 0) ||
                      ((isShowSchedulePayments
                        ? selectedIndex === 2
                        : selectedIndex === 1) &&
                        payments.length === 0)
                    ) {
                      const text =
                        isShowSchedulePayments && selectedIndex === 1
                          ? "No Scheduled payments"
                          : "No History Payments";
                      return (
                        <View>
                          <Text
                            style={{
                              padding: 10,
                              fontSize: 14,
                              color:
                                transactionType === TRANSACTION_TYPE_LENT
                                  ? LENT
                                  : OWES,
                              textAlign: "center",
                            }}
                          >
                            {text}
                          </Text>
                        </View>
                      );
                    }
                  }
                  const text = `${
                    title === "lastSection"
                      ? selectedIndex === 0
                        ? ""
                        : hasSetPlan && selectedIndex === 1
                        ? ""
                        : hasSetPlan
                        ? "Repayments (latest first)"
                        : "Payments Made (latest first)"
                      : title
                  }`;
                  return text === "" || text.length === 1 ? null : (
                    <View style={styles.sectionHeader}>
                      {title.length !== 1 && data.length > 0 && (
                        <Text style={styles.sectionHeaderText}>{text}</Text>
                      )}
                    </View>
                  );
                }}
                renderItem={(item, index) => {
                  sections[index].renderItem({ item, index });
                }}
              />
              <View style={{ height: 15 }} />
            </View>
          </View>
        )}
        {!isMobile && (
          <iframe
            src={WEBSITE_URL}
            title="Free Bird Website"
            className="website-iframe-right"
          />
        )}
      </div>
    </div>
  );
});

const ShareFooter = ({ openModal, invitation, utmCampaign }) => {
  const styles = useDynamicStyleSheet(dStyles);

  return (
    <View className={`footer ${!isMobile ? "footer-shrinked" : ""}`}>
      <Text>
        This invitation link expires{" "}
        <strong>{moment(invitation.expiresAt).from(moment())}</strong>
      </Text>
      <hr />
      <View style={{ width: "100%" }}>
        {!isMobile && (
          <View style={{ alignItems: "space-betwen" }}>
            <Text style={{ textAlign: "center" }} className="animated pulse">
              Want to avoid link expiry?
            </Text>
            <View style={styles.appInstallContainer}>
              <a href="https://apps.apple.com/in/app/free-bird-simple-debt-tracker/id1244075045">
                <Image
                  source={require("../../assets/installers/app-store-badge.png")}
                  style={{ height: 150 }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.tilicho.lendpal&hl=en_IN&gl=US">
                <Image
                  source={require("../../assets/installers/google-play-badge.png")}
                  style={{ height: 75 }}
                />
              </a>
            </View>
            <TouchableOpacity
              onPress={openModal}
              style={styles.seeAdvantagesButton1}
            >
              <Text className="find-advantages">Find out advantages</Text>
            </TouchableOpacity>
          </View>
        )}
        {isMobile && (
          <View style={{ flexDirection: "column", alignItems: "center" }}>
            <Text style={styles.appAdvantagesText} className="animated pulse">
              Want to avoid link expiry?
            </Text>
            <a
              href={deepLinkURLv3({
                invitationId: invitation.id,
                utmCampaign,
              })}
            >
              <Image
                source={
                  isIOS
                    ? require("../../assets/installers/app-store-badge.png")
                    : require("../../assets/installers/google-play-badge.png")
                }
                style={{
                  height: isIOS ? 150 : 75,
                  marginTop: isIOS ? -45 : 0,
                }}
              />
            </a>
            <TouchableOpacity
              onPress={openModal}
              style={
                isIOS
                  ? styles.seeAdvantagesButton
                  : styles.seeAdvantagesButtonAndroid
              }
            >
              <Text className="find-advantages">Find out advantages</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

const AppInstallAdvantages = ({ invitation, utmCampaign }) => {
  const styles = useDynamicStyleSheet(dStyles);

  return (
    <View style={styles.appInstallAdvantages}>
      <h2>App Advantages</h2>
      <ul className="normal">
        <li>
          <div className="advantage-title">
            Shared loans are tracked forever!
            <div className="advantage-description">
              (unless access is revoked by the sharer)
            </div>
          </div>
        </li>
        <li>
          <div className="advantage-title">
            Receive push notifications
            <div className="advantage-description">
              (when payment is added/removed or loan is modified)
            </div>
          </div>
        </li>
        <li>
          <div className="advantage-title">
            Export loan to your device
            <div className="advantage-description">
              (with beautiful pdf & excel formats)
            </div>
          </div>
        </li>
        <li>
          <div className="advantage-title">
            Optional mobile number sign-in
            <div className="advantage-description">
              (choose to be anonymous while tracking loan)
            </div>
          </div>
        </li>
        <li>
          <div className="advantage-title">
            Track loan with loans added by you
            <div className="advantage-description">
              (shared loans appear in your summary)
            </div>
          </div>
        </li>
        <li>
          <div className="advantage-title">
            And more...!
            <div className="advantage-description">
              Pin shared loans, use dark mode and so on!
            </div>
          </div>
        </li>
      </ul>
      {isMobile && (
        <a
          href={deepLinkURLv3({
            invitationId: invitation.id,
            utmCampaign,
          })}
        >
          <Image
            source={
              isIOS
                ? require("../../assets/installers/app-store-badge.png")
                : require("../../assets/installers/google-play-badge.png")
            }
            style={{
              height: isIOS ? 150 : 75,
              marginTop: isIOS ? -45 : 0,
            }}
          />
        </a>
      )}
    </View>
  );
};
